//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import L from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { mapGetters } from 'vuex';

export default {
  name: 'app-place-form-location',

  props: {
    value: {
      type: Object
    },
    radius: {
      type: Number
    },
    fillColor: {
      type: String
    },
    strokeColor: {
      type: String
    }
  },
  data () {
    return{
      location: '',
      locationResults: [],
      map: null,
      marker: null,
      circle: null,
      popupShow: false,
      loadingLocation: false
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
  watch:{
    // value(newval){
    //   console.log(newval);
    //   debugger
    // },
    radius(newval){
      if(newval){
        this.circle.setRadius(newval)
      }
    },
    fillColor(newval){
      if(newval){
        this.circle.setStyle({ 
          fillColor: newval,
          color: this.color 
        })
      }
    },
    strokeColor(newval){
      if(newval){
        this.circle.setStyle({
          fillColor: this.fillColor,
          color: newval 
        })
      }
    }
  },
  mounted() {
    let greenIcon = L.icon({
      // iconUrl: '/images/map/trip-marker.svg',
      // shadowUrl: '/images/map/trip-marker.svg',
      iconUrl: '/images/map/marker.svg',
      iconSize: [38, 95],         // size of the icon
      shadowSize: [50, 64],       // size of the shadow
      iconAnchor: [22, 94],       // point of the icon which will correspond to marker's location
      shadowAnchor: [4, 62],      // the same for the shadow
      popupAnchor:  [-3, -76]     // point from which the popup should open relative to the iconAnchor
    });

    this.map = L.map('mapPlace').setView([this.value.lat, this.value.lng], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    this.marker = L.marker([this.value.lat, this.value.lng], {
      icon: greenIcon,
      draggable: true
    }).addTo(this.map)

    this.marker.on('dragend',this.onMarkerDrag)
    this.circle = L.circle([this.value.lat, this.value.lng], {
      color: this.value.strokeColor,
      fillColor: this.value.fillColor,
      fillOpacity: 0.5,
      radius: this.value.radius
    }).addTo(this.map);
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    async searchLocation(){
      this.popupShow = true
      const provider = new OpenStreetMapProvider();  
      this.locationResults = await provider.search({ query: this.location });
    },
    filterFn (val, update) {
      if (val === '') {
        update(() => {
           // options.value = stringOptions
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }
      update(async () => {
        this.loadingLocation = true
        const provider = new OpenStreetMapProvider();  
        this.locationResults = await provider.search({ query: val });
        this.loadingLocation = false
      })
    },
    changeMapMarker(item){
      let latlng = L.latLng(item.raw.lat, item.raw.lon); 
      this.marker.setLatLng(latlng)
      this.circle.setLatLng(latlng)
      this.map.panTo({lon: item.raw.lon, lat: item.raw.lat});
      // this.value.location = {
      //     longitude: parseFloat(item.raw.lon),
      //     latitude: parseFloat(item.raw.lat)
      // }
      this.value.lat = parseFloat(item.raw.lat)
      this.value.lng = parseFloat(item.raw.lon)
    },
    onMarkerDrag(e) {
      this.value.lat = e.target._latlng.lat
      this.value.lng = e.target._latlng.lng
      this.circle.setLatLng(e.target._latlng)
    },
  },
}
