//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { PlaceModel } from '@/modules/place/place-model';
import axios from 'axios';
import PlaceFormLocation from '@/modules/place/components/place-form-location.vue'

const { fields } = PlaceModel;
const formSchema = new FormSchema([
  fields.id,
  fields.placeName,
  fields.description,
  fields.placePictures,
  fields.cityId,
  fields.countryId,
  fields.placeType,
  fields.placeTypeAsEnum,

  fields.address,
  fields.lat,
  fields.lng,
  fields.radius,
  fields.strokeColor,
  fields.strokeWidth,
  fields.fillColor,
  fields.notifications,
]);

export default {
  name: 'app-place-form-page',
  props: ['id'],
  components:{
    [PlaceFormLocation.name]: PlaceFormLocation
  },

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      center: { lat: 21.485811, lng: 39.19250479999999 },
      markers: [
        { position: { lat: 21.485811, lng: 39.19250479999999 } },
      ],
      colors: {
        hex: '#000000',
      },
      Scolors: {
        hex: '#000000',
      },
      colorValue: '000000',
      defaultColor: '#FF0000',
      fillColor: '#AFE3E6',
      strokeColor: '#CEEAEB',
      radiusValue: 600,
      strokeWeight: 100,

      address: {},
      citiesOptions: [],
      displayPicker: false,
      displaySPicker: false,

      currentPlace: null,
      places: [],
    };
  },
  computed: {
    ...mapGetters({
      record: 'place/form/record',
      findLoading: 'place/form/findLoading',
      saveLoading: 'place/form/saveLoading',
      citiesRows: 'city/list/rows',  
      loadingCities: 'city/list/loading',  
      autocompleteList: 'country/list/autocompleteList',
      autocompleteLoading: 'country/list/autocompleteLoading',
      cityAutocompleteList: 'city/list/autocompleteList',
      cityAutocompleteLoading: 'city/list/autocompleteLoading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
      currentLocation: 'layout/currentLocation',
    }),
    isEditing() {
      return !!this.id;
    },
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
    fields() {
      return fields;
    },
    placeType() {
      return fields.placeTypeAsEnum.options
    },
    countryOptions() {
      if (this.isEditing && !this.autocompleteList.length && !this.autocompleteLoading) {
        return this.record && this.record['country']
          ? [{ id: this.record.countryId, label: this.record.country.name[this.currentLanguageCode] }] 
          : []
      }
      return this.autocompleteList
    },
    cityOptions() {
      if (this.isEditing && !this.cityAutocompleteList.length && !this.cityAutocompleteLoading) {
        return this.record && this.record['city']
          ? [{ id: this.record.cityId, label: this.record.city.name[this.currentLanguageCode] }] 
          : []
      }
      return this.cityAutocompleteList
    },
  },
  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          let pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          this.center = pos
          this.markers = [
            {
              position: pos,
            },
          ]
        }, 
        () => {
          // handleLocationError(true, infoWindow, map.getCenter());
          console.log(`Browser doesn't support Geolocation`);
        });
      } else {
        // Browser doesn't support Geolocation handleLocationError(false, infoWindow, map.getCenter());
        console.log(`Browser doesn't support Geolocation`);
      }
      await this.doNew();
    }
    this.model = formSchema.initialValues(this.record);
    this.model.placeName = this.model.placeName || { en: undefined, ar: undefined }
    this.model.description = this.model.description || { en: undefined, ar: undefined }
    this.model.address = this.model.address || { en: undefined, ar: undefined }
    this.model.lat = this.model.lat ? this.model.lat : this.currentLocation.latitude || 21.485811
    this.model.lng = this.model.lng ? this.model.lng : this.currentLocation.longitude || 39.19250479999999
    this.model.fillColor = this.model.fillColor || '#AFE3E6';
    this.model.strokeColor = this.model.strokeColor || '#CEEAEB';
    this.model.strokeWidth = this.model.strokeWidth || 10;
    this.model.radius = this.model.radius || 600;
    
    if (this.isEditing) {
      this.colorValue = this.model.fillColor;
      this.radiusValue = this.model.radius;
      this.strokeColor = this.model.strokeColor;
      this.strokeWeight = this.model.strokeWidth;
      this.center.lat = this.model.lat;
      this.center.lng = this.model.lng;
    } 
  },
  methods: {
    ...mapActions({
      doFind: 'place/form/doFind',
      doNew: 'place/form/doNew',
      doUpdate: 'place/form/doUpdate',
      doCreate: 'place/form/doCreate',

      doFetchCountryAutocomplete: 'country/list/doFetchCountryAutocomplete',
      doFetchCityAutocomplete: 'city/list/doFetchCityAutocomplete',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    getCountryAutoComplete(query) {
      this.doFetchCountryAutocomplete({
        search: query, 
      })
    },
    getCityAutoComplete(query) {
      debugger
      this.doFetchCityAutocomplete({
        search: query, 
        countryId: this.model.countryId,
      })
    },
    /**
     * loadControls function specified in create add my current location button and bind all style
     * to this button , and add listener to that button when clicked to call the blew child
     * that call another function called getCurrentPosition that return the current location
     * finally set the position of this button on  RIGHT_BOTTOM as a custom element
     */
    loadControls(map) {
      console.log(map);
      let controlDiv = document.createElement('div');
      let firstChild = document.createElement('button');
      firstChild.style.backgroundColor = '#fff';
      firstChild.style.border = 'none';
      firstChild.style.outline = 'none';
      firstChild.style.width = '28px';
      firstChild.style.height = '28px';
      firstChild.style.borderRadius = '2px';
      firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
      firstChild.style.cursor = 'pointer';
      firstChild.style.marginRight = '10px';
      firstChild.style.padding = '0px';
      firstChild.title = 'Your Location';
      controlDiv.appendChild(firstChild);
      let secondChild = document.createElement('div');
      secondChild.style.margin = '5px';
      secondChild.style.width = '18px';
      secondChild.style.height = '18px';
      secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
      secondChild.style.backgroundSize = '180px 18px';
      secondChild.style.backgroundPosition = '0px 0px';
      secondChild.style.backgroundRepeat = 'no-repeat';
      secondChild.id = 'you_location_img';
      firstChild.appendChild(secondChild);
      window.google.maps.event.addListener(
        this.$refs.map.$mapObject,
        'center_changed',
        function() {
          secondChild.style['background-position'] = '0 0';
        },
      );
      let ref = this;
      firstChild.addEventListener('click', function() {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // let latlng = 
            new window.google.maps.LatLng(
              parseFloat(position.coords.latitude),
              parseFloat(position.coords.longitude),
            );
            ref.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          },
        );
      });
      controlDiv.index = 1;
      this.$refs.map.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].push(controlDiv);
    },

    // submitting place form ,  first check validation then catch error if exist
    // make an http request to google map that request the city and country with given lat and lang
    // then it returns the city and country to set them into database
    // finally assign this fields { lng , lat , fillColor ,  radius , strokeColor , strokeWidth , city , country  }
    // cast model with this fields
    // call function setPlaces and pass values to it
    // if user is editing then call doUpdate function else call doCreate function
    async doSubmit() {
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }

      const lat = this.center.lat;
      const lng = this.center.lng;

      await axios
        .get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyAP6KUv6fM-DyHHSVedSYpKsKnX5PUT_Z8')
        .then((response) => {
          console.log(response);
          for ( let i = 0; i < response.data.results.length; i++ ) {
            for ( let j = 0; j < response.data.results[i].address_components.length; j++ ) {
              for ( let k = 0; k < response.data.results[i].address_components[j].types.length;  k++) {
                if (
                  response.data.results[i].address_components[j].types[k] == 'locality'
                ) {
                  let town = response.data.results[i].address_components[j].long_name;
                  this.address.city = town;
                } else if (
                  response.data.results[i].address_components[j].types[k] == 'country'
                ) {
                  let country = response.data.results[i].address_components[j].long_name;
                  this.address.country = country;
                }
              }
            }
          }
        });

      this.model.lng = this.center.lng;
      this.model.lat = this.center.lat;
      this.model.fillColor = this.colorValue;
      this.model.radius = this.radiusValue;
      this.model.strokeColor = this.strokeColor;
      this.model.strokeWidth = this.strokeWeight;

      const { id, ...values } = formSchema.cast(this.model);
      if (this.isEditing) {
        return await this.doUpdate({ id, values });
      } else {
        return await this.doCreate(values);
      }
    },

    //  setPlcae function take place and assigned it to currentPlace
    //  set address of that place with street , city and country

    setPlace(place) {
      this.currentPlace = place;
      // this.model.placeName[this.currentLanguageCode] = place.name;
      this.model.placeName['en'] = place.name;
      this.model.placeName['ar'] = place.name;

      this.address = {
        street: place.address_components[0].long_name,
        city: place.address_components[0].long_name,
        country: place.address_components[0].long_name,
      };
    },

    // addMarker function check first if CurrentPlace if yes
    // set marker to that place
    // push this place to array places which consist of lists of all places
    // then assign center with lat and lng to center of that marker
    // finally set current place to null value
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };

        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },

    // geolocate function call get current postion and set center to that position lat and lng
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        },
      );
    },

    // updateCoordinates take location as parameter and set center lat and lng to that location
    updateCoordinates(location) {
      this.center = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    setSColor(color) {
      this.updateSColors(color);
      this.strokeColor = color;
    },

    // this collection of functions { updateColors , updateSColors , showPicker  , showSPicker }
    // , { hidePicker , hideSPicker , togglePicker , toggleSPicker , updateFromInput }
    // and  { updateFromSInput , updateFromPicker ,  updateFromSPicker , documentClick , documentSClick  }
    // all this functions for color picker and bind it's values and show popup of that picker in responsive form
    // then watch for { colorValue and strokeColor }
    updateColors(color) {
      if (color.slice(0, 1) == '#') {
        this.colors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == 'rgba') {
        let rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex = '#' + ( (1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) +  parseInt(rgba[2]) ).toString(16).slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    updateSColors(color) {
      if (color.slice(0, 1) == '#') {
        this.Scolors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == 'rgba') {
        let rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
        this.Scolors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    showSPicker() {
      document.addEventListener('click', this.documentSClick);
      this.displaySPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    hideSPicker() {
      document.removeEventListener('click', this.documentSClick);
      this.displaySPicker = false;
    },
    togglePicker() {
      this.displayPicker
        ? this.hidePicker()
        : this.showPicker();
    },
    toggleSPicker() {
      this.displaySPicker
        ? this.hideSPicker()
        : this.showSPicker();
    },
    updateFromSInput() {
      this.updateSColors(this.strokeColor);
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    updateFromSPicker(color) {
      this.Scolors = color;
      if (color.rgba.a == 1) {
        this.strokeColor = color.hex;
      } else {
        this.strokeColor = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    documentClick(e) {
      let el = this.$refs.colorpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    },
    documentSClick(e) {
      let el = this.$refs.colorSpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hideSPicker();
      }
    },
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.updateColors(val);
        this.$emit('input', val);
      }
    },
    strokeColor(val) {
      if (val) {
        this.updateSColors(val);
        this.$emit('input', val);
      }
    },
  },
};
