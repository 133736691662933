import { render, staticRenderFns } from "./place-form-page.vue?vue&type=template&id=c2a95aa8&scoped=true&"
import script from "./place-form-page.vue?vue&type=script&lang=js&"
export * from "./place-form-page.vue?vue&type=script&lang=js&"
import style0 from "./place-form-page.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./place-form-page.vue?vue&type=style&index=1&id=c2a95aa8&lang=scss&scoped=true&"
import style2 from "./place-form-page.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2a95aa8",
  null
  
)

export default component.exports
import {QBreadcrumbs,QIcon,QBreadcrumbsEl,QSeparator,QForm,QInput,QTooltip,QPopupProxy,QColor,QSelect,QItem,QItemSection,QRadio,QItemLabel,QBtn,QSpinnerIos,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBreadcrumbs,QIcon,QBreadcrumbsEl,QSeparator,QForm,QInput,QTooltip,QPopupProxy,QColor,QSelect,QItem,QItemSection,QRadio,QItemLabel,QBtn,QSpinnerIos,QField})
